import React, { useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import auth from "./auth-helper";
import { useLocation, useNavigate } from "react-router-dom";
import { signin } from "./api-auth.js";
import Logo from "./../assets/images/better_v.png";
import { UserContext } from "../user/user-context";
import { CircularProgress } from "@mui/material";
import Modal from 'react-bootstrap/Modal';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  loadingCard: {
    maxWidth: 600,
    height: 560,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: "black",
    fontfamily: "Montserrat",
    letterSpacing: "5px",
    fontSize: "26px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
    backgroundColor: "black",
    // border: "1px solid white",
    color: "white",
    borderRadius: "130px",
    width: "120px",
    padding: "10px",
  },
  logoCnt: {
    height: "128px",
    padding: 0,
  },
  logo: {
    height: "160px",
    width: "340px",
    margin: "auto",
  },
}));

export default function Signin(props) {
  const classes = useStyles();
  const state = useLocation();
  const { user, setUser } = useContext(UserContext);
  const to = state || {
    from: "/",
  };
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const clickSubmit = (e) => {
    e.preventDefault();
    const user = {
      email: values.email || undefined,
      password: values.password || undefined,
    };

    signin(user)
      .then((resp) => {
        setValues({ ...values, loading: true });
        const { data } = resp;
        auth.authenticate(data, () => {
          setUser(data.user);
          let { pathname } = to;
          if (pathname) {
            if (pathname.includes("signin")) {
              pathname = "/";
            }
            window.location.href = pathname;
          } else {
            navigate(to.from);
          }
          setValues({ ...values, loading: false });
        });
      })
      .catch((error) => {
        const { data } = error.response;
        setValues({ ...values, error: data.error, loading: false });
      });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  return (
    <div>
      {values.loading ? (
        <Card className={classes.loadingCard}>
          <CardContent>
            <CircularProgress></CircularProgress>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <img className={classes.logo} src={Logo} srcSet={Logo} />
            <Typography variant="h6" className={classes.title}>
              Welcome to Better Vision
            </Typography>
            <TextField
              id="email"
              type="email"
              label="Email"
              className={classes.textField}
              value={values.email}
              onChange={handleChange("email")}
              margin="normal"
            />
            <br />
            <TextField
              id="password"
              type="password"
              label="Password"
              className={classes.textField}
              value={values.password}
              onChange={handleChange("password")}
              margin="normal"
            />
            <br />{" "}
            {values.error && (
              <Typography component="p" color="error">
                <Icon color="error" className={classes.error}></Icon>
                {values.error}
              </Typography>
            )}

            <div className="mt-3">
              <input type="checkbox" />
              <label><a style={{ color: "blue", marginLeft: "10px" }} onClick={() => setLgShow(true)}><u>disclaimer and Terms & Conditions</u></a></label>
            </div>
          </CardContent>
          <CardActions>

            <Button
              color="primary"
              variant="contained"
              onClick={clickSubmit}
              className={classes.submit}
            >
              Sign In
            </Button>
          </CardActions>
          <div>
            <Link to="/forgotpassword">
              <Button color="inherit">Forgot Password</Button>
            </Link>
          </div>
          <div>
            <Link to="/signup">
              <Button color="inherit">
                Do not have an account? Click to Sign Up
              </Button>
            </Link>
          </div>
        </Card>
      )}

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Disclaimer and Terms & Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <p>Last Updated: 11-Dec-2024</p>
            <p>By accessing or using our software (the "Service"), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use the Service.</p>

            <ol>
              <li>
                <h4>Store Credits</h4>
                <ul style={{ listStyle: "square" }}>
                  <li>Store credits can be added to your account and are valid for 24 months from the date of addition. After this period, any unused credits will expire and be forfeited.</li>
                  <li>Store credits are non-refundable and cannot be exchanged for cash.</li>
                  <li>Store credits are non-transferable except at the discretion of the company.</li>
                </ul>
              </li>

              <li>
                <h4>Confidentiality Clause</h4>
                <p>We are committed to maintaining the confidentiality of your information. Any personal data collected during your use of the Service will be handled in accordance with our Privacy Policy. We will not disclose your information to third parties without your consent, except as required by law or to provide the Service.</p>
              </li>

              <li>
                <h4>Communication</h4>
                <p>By signing up for the Service, you consent to receive communications from us related to your store credits and marketing content. You can opt out of marketing communications at any time; however, you will still receive essential account-related messages.</p>
                <p>You acknowledge and agree that the In Store Credit App will use your personal information to manage your access to the Mobile Wallet Providers, provide updates, and deliver targeted offers and advertisements periodically.</p>
                <h4>Referral Information</h4>
                <p>We will request your friend’s contact information if you elect to use our referral service to tell a friend about our Service. We may use telephone numbers, email addresses, Facebook and other social networks to invite friends to use the Service. This contact information is stored in order to invite your friends to use the Service and to track the success of our referral program</p>
              </li>

              <li>
                <h4>Limitations</h4>
                <ul style={{ listStyle: "square" }}>
                  <li>Store credits cannot be combined with other offers or promotions.</li>
                  <li>The company reserves the right to amend these Terms and Conditions at any time. Any changes will be effective immediately upon posting in the Service.</li>
                </ul>
              </li>

              <li>
                <h4>Governing Law</h4>
                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].</p>
              </li>

              <li>
                <h4>Data Breach Procedure and Response Plan</h4>
                <p>In the event that the In Store Credit App experiences a data breach, we will follow our established Data Breach Procedure and Response Plan. A data breach is defined as the loss of, unauthorized access to, or unauthorized disclosure of personal information.</p>
                <p>Upon identification of a breach, we will assess the situation and, if necessary, notify the relevant individuals. Appropriate measures will be taken to mitigate the impact of the breach and ensure the protection of personal information.</p>
                <p><b>Security – What Steps Do We Take To Protect Your Information Online?</b></p>
                <p>Reasonable measures are taken to protect your personal information in order to prevent loss, misuse, unauthorised access, alteration and destruction. Please be aware that you are responsible for all uses of your mobile device by any person and we advise to add a passcode lock on your mobile device at all times. Please advise us immediately if you believe your account has been misused, and if you have any questions regarding the security of our service, please contact us at management@bettervision.com.au</p>
              </li>


              <li>
                <h4>Acceptance of Terms</h4>
                <p>By using the Service, you acknowledge that you have read, understood, and agree to these Terms and Conditions. If you have any questions regarding these terms, please contact us at [Your Contact Information].</p>
              </li>


              <li>
                <h4>Non-Disclosure Agreement </h4>
                <p>By agreeing to use the In Store Credit App, you also agree to the terms of the Non-Disclosure Agreement (NDA), which protects any confidential information related to your use of the service. This agreement ensures that all personal and sensitive data remains secure and is not disclosed to unauthorized parties. Breaches of the NDA may result in the cancellation of your app membership and forfeiture of all credits associated with your account. Any such breaches will be addressed in accordance with applicable laws and regulations.</p>
              </li>

              <li>
                <h4>Inquiries and Complaints</h4>
                <p>If you have a query or complaint about the In Store Credit App, please contact us by emailing management@bettervision.com.au or calling (02) 9831 5999 during AEST business hours. </p>
              </li>

            </ol>





          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
